import React, { useContext, useState } from "react";
import SignInWithGoogle from "./general/SignInWithGoogle";
import { useLocation, useNavigate } from "react-router-dom";
import useFirebaseAuth from "../auth/useFirebaseAuth";
import { LoginQuery } from "../utils/auth";
import { setToken, setUserCookie } from "../auth/cookies";
import Header from "./general/Header";
import { toastError } from "../utils/Toasters";
import { toast, ToastContainer } from "react-toastify";
import { StateContext } from "./Context/StateContext";
import styles from "../styles/modular css/Login.module.css";
import ForgotPasswordModal from "./general/Modals/ForgotPasswordModal";
import { LinkedIn } from "./general/icons/Icons";
import firebase from "../auth/firebaseConfig";
import SignInWithLinkedIn from "./SignInWithLinkedIn";

function LoginForm() {
  const { loading, setFirstLoading } = useContext(StateContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const navigate = useNavigate();
  const { signInWithEmailAndPassword, signInWithGoogle, forgotPassword } =
    useFirebaseAuth();
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  let linkTo = query?.get("navigateTo");

  const handleLoginApiCall = (authUser) => {
    var raw = {
      email: email.trim(),
      password: password.trim(),
    };
    LoginQuery(
      authUser ? authUser?.user?.multiFactor?.user?.accessToken : null,
      raw
    )
      .then((response) => {
        return response.json();
      })
      .then(async (response) => {
        if (response?.status == true) {
          setUserCookie(response);
          if (
            response?.token ||
            response?.token != null ||
            response?.token != undefined
          )
            setToken(response?.token);

          if (linkTo && linkTo != null && linkTo != undefined) navigate(linkTo);
          else navigate("/dashboard");
        } else {
          console.log("response status false", response);
          toastError(response.message);
        }
        setFirstLoading(false);
      })
      .catch((error) => {
        setFirstLoading(false);
        toastError(error.message);
      });
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    setFirstLoading(true);
    signInWithEmailAndPassword(email.trim(), password.trim())
      .then(async (authState) => {
        setToken(authState?.user?.multiFactor?.user?.accessToken);
        handleLoginApiCall(authState);
      })
      .catch((error) => {
        handleLoginApiCall();
        setFirstLoading(false);
      });
  };

  const forgotPasswordHandler = async (setLoading, mail) => {
    setLoading(true); // Set loading to true when submitting

    try {
      let response = await forgotPassword(mail);
      if (response && response.status == true) {
        toast.success("Password reset link sent successfully!", {
          bodyClassName: "grow-font-size",
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // toastSuccess("Password reset link sent successfully!");
        setShowForgotPassword(false);
      }
    } catch (error) {
      toastError(error?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div
      className={`d-flex d-flex-column gap-4 mt-5 ${styles.loginFormWrapper}`}
    >
      <div className="aqua-heading">
        Your future is <br />
        futr
      </div>

      {/* Form */}
      <form
        className="d-flex d-flex-column gap-3 d-justify-center"
        onSubmit={loginHandler}
      >
        <div
          className="d-flex d-align-center form-input-field-div gap-2 p-3"
          style={{ height: "65px" }}
        >
          <img src="/assets/email-envelope-icon.svg" />
          <div className="h-full w-full">
            <input
              className="border-none w-full h-full form-input-field"
              placeholder="Enter your email"
              type="email"
              value={email}
              required={true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        {/* <input
          className="input-field p-2"
          placeholder="Enter your email"
          style={{ paddingLeft: "8%" }}
          type="text"
          value={email}
          required={true}
          onChange={(e) => setEmail(e.target.value)}
        /> */}

        <div
          className="d-flex d-align-center form-input-field-div gap-2 p-3"
          style={{ height: "65px" }}
        >
          <img src="/assets/password-lock-icon.svg" />
          <div className="h-full col-8 border-right-grey">
            <input
              className="border-none w-full h-full form-input-field"
              placeholder="New Password"
              type={showPassword ? "text" : "password"}
              value={password}
              required={true}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div
            role="button"
            className="d-flex d-justify-center col-2 font-20 f-400 text-sea-green cursor-pointer"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "Hide" : "Show"}
          </div>
        </div>

        {/* <input
          className="input-field p-2"
          placeholder="Password"
          style={{ paddingLeft: "8%" }}
          type="password"
          required={true}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        /> */}

        <p
          className="text-light-grey2 cursor-pointer"
          style={{ marginBlock: "0" }}
          onClick={() => setShowForgotPassword(true)}
        >
          Forgot Password?
        </p>
        <button className="btn btn-sea-green font-20 l-32" type="submit">
          Sign In
        </button>
        <div className="h4 f-400 l-20 text-center">Or</div>

        <SignInWithGoogle />

        <SignInWithLinkedIn text="Sign In with LinkedIn" />
      </form>
      <div className="font-20 text-center mt-10">
        Don't have an account?
        <span
          className="text-sea-green font-20 f-600 cursor-pointer"
          onClick={() => navigate("/register")}
        >
          {" "}
          Join Now
        </span>
      </div>

      {showForgotPassword && (
        <ForgotPasswordModal
          submitHandler={forgotPasswordHandler}
          closeModal={() => setShowForgotPassword(false)}
        />
      )}
    </div>
  );
}
export default LoginForm;
