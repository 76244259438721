// import hooks
import { useState, useRef, useEffect} from "react";

// import components

import Products from "./companyProfile/products/Products";
import Finance from "./companyProfile/Finance";
import Metrics from "./companyProfile/Metrics";
import AwardsCategory from "./companyProfile/AwardsCategory";
import ReferencesCategory from "./companyProfile/ReferencesCategory";
import Feedback from "./companyProfile/Feedback";
import People from "./companyProfile/People";
import NewsCategory from "./companyProfile/NewsCategory";
import Content from "./companyProfile/content/Content";
import Footer from "./general/Footer";
import { Link, useLocation, useParams } from "react-router-dom";
import LoggedInHeader from "./general/LoggedInHeader";
import { toastError } from "../utils/Toasters";
import { getMethod } from "../utils/apiMethod";
import CompanyDetails from "./companyProfile/CompanyDetails";
import Sources from "./companyProfile/Sources";
import Privacy from "./companyProfile/Privacy";
import styles from "../styles/modular css/CompanyPublicProfile.module.css"
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const bgImage = {
  backgroundImage: 'url(/assets/Star.svg)',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  backgroundRepeat : 'no-repeat'
};


function CompanyPublicProfile() {

   const { companyId } = useParams() 
    const location = useLocation();
    const [category, setCategory] = useState("Products");
    const [productTypes, setProductTypes] = useState([]);
    const [address, setAddress] = useState("");

 useEffect(() => {
   getMethod(`users/viewCompany/${companyId}`)
     .then((res) => {
       if (res?.status) {
        setAddress(res?.companyDetails?.company?.companyAddresses)
       }
     })
     .catch((error) => {
       toastError(error.message);
     });
 }, []);

 useEffect(() => {
   const hash = location.hash.substring(1);
   if(hash) setCategory(hash)
   else setCategory("Products");
 }, [location]);

 const handleChangeCategory = (val) => {
  setCategory(val)
   window.open(
     `/companyProfile/${companyId}#${val}`,
     "_self"
   );
 }

  return (
    <div className="d-flex d-flex-column gap-2">
      <LoggedInHeader />
      <div style={{ marginTop: "180px" }}>
        <div
          className={`${styles.wrapper} container d-flex d-flex-column gap-15`}
        >
          <CompanyDetails
            companyId={companyId}
            fetchProductTypes={setProductTypes}
          />

          {/* SECTION - 2 : Products, People, Finance, etc */}
          <div
            className={`${styles.contentWrapper} d-flex container col-11`}
          >
            {/* Side panel */}
            <div className={`${styles.sidePanel} col-12 col-xl-2`}>
              <div
                className="col-12 col-xl-10 d-flex d-flex-column gap-2 rounded-8 p-5"
                style={{
                  background:
                    "linear-gradient(180deg, #F5F5F5 0%, #EEF9FB 94.91%)",
                  boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)",
                }}
              >
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "Products"
                      ? "bg-sea-green text-white cursor-default cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("Products");
                  }}
                >
                  Products
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "People"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("People");
                  }}
                >
                  People
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "Finance"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("Finance");
                  }}
                >
                  Finance
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "Metrics"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("Metrics");
                  }}
                >
                  Metrics
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "Content"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("Content");
                  }}
                >
                  Content
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "News"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("News");
                  }}
                >
                  News
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "Awards"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("Awards");
                  }}
                >
                  Awards
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "References"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("References");
                  }}
                >
                  References
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "Feedback"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("Feedback");
                  }}
                >
                  Feedback
                </div>
                <div
                  className={`rounded-8 p-2 font-16 f-400 ${
                    category === "Privacy"
                      ? "bg-sea-green text-white cursor-default"
                      : "text-black bg-none cursor-pointer"
                  }`}
                  onClick={() => {
                    handleChangeCategory("Privacy");
                  }}
                >
                  Privacy
                </div>
              </div>
            </div>

            {/* Viewing window */}
            <div className={`${styles.viewingWindow} col-12 col-xl-10`}>
              {/* Side Panel Category : PRODUCTS  */}

              {category === "Products" && (
                <Products companyId={companyId} productTypes={productTypes} />
              )}

              {/* Side Panel Category : Finance  */}
              {category === "Finance" && <Finance companyId={companyId} />}
              {/* Side Panel Category : Metrics  */}
              {category === "Metrics" && <Metrics companyId={companyId} />}

              {/* Side Panel Category: Awards */}
              {category === "Awards" && (
                <AwardsCategory companyId={companyId} />
              )}
              {/* Side Panel Category: References */}
              {category === "References" && (
                <ReferencesCategory companyId={companyId} />
              )}
              {/* Side Panel Category: Feedback */}
              {category === "Feedback" && <Feedback companyId={companyId} />}
              {/* Side Panel Category: People */}
              {category === "People" && <People companyId={companyId} />}

              {/* Side Panel Category: News */}
              {category === "News" && <NewsCategory companyId={companyId} />}
              {/* Side Panel Category: Content */}
              {category === "Content" && (
                <Content companyId={companyId} productTypes={productTypes} />
              )}
              {category === "Privacy" && <Privacy companyId={companyId} />}
            </div>
          </div>
          {/* SECTION - 3 : Sources and Social & Locations */}

          <div className="d-flex d-flex-column mb-5 container col-11">
            {/* SOURCES AND SOCIAL */}
            <Sources companyId={companyId} />

            {/* LOACTIONS */}

            <div className={`bg-white p-10`}>
              <div className="d-flex d-flex-column gap-3 mt-5 mb-5">
                {/* heading */}
                <div className="font-48 f-700 l-54 text-blue">Locations</div>
                {/* links */}
                <div className={`${styles.locationGrid} d-grid grid-col-4`}>
                  {address &&
                    address?.map((item, index) => (
                      <div
                        key={index + 1}
                        className="d-flex d-flex-column gap-1"
                      >
                        {item?.label == "hq" && (
                          <div className="h3 f-600 l-39 text-blue">
                            Headquarters
                          </div>
                        )}
                        <div className="text-grey-7 font-20 f-400 l-30">
                          {item?.address}, {item?.city}, {item?.state}
                        </div>
                        <div className="text-grey-7 font-20 f-400 l-30">
                          {item?.zipCode}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer/>
    </div>
  );
}

export default CompanyPublicProfile;
