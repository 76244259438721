import React, { useState } from "react";
import OptionsPopup from "../general/Modals/OptionsPopup.jsx";
import Loader from "./Loader.jsx";
import styles from "../../styles/modular css/SearchBar.module.css"

function SearchBar({ options, fetchSearchValues, hideDropdown }) {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    option: "All",
    productTypeId: null,
  });
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  function setSelectedOptionValue(name, id) {
    let values = {
      option: name,
      productTypeId: id,
    };
    setSelectedOption(values);
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (selectedOption.productTypeId === null) {
      fetchSearchValues(searchValue);
    } else {
      fetchSearchValues(searchValue, selectedOption.productTypeId);
    }
  };

  return (
    <>
      <form onSubmit={submitHandler}>
        <div className={`${styles.searchWrapper} d-flex d-justify-between`}>
          <div
            className={`${styles.searchFields} col-12 bg-white d-flex border-grey rounded-8`}
          >
            {/* Dropdown */}
            <div
              className="border-right-grey text-center d-flex gap-1 d-align-center p-2 p-relative cursor-pointer"
              onClick={() => {
                setShowPopup(!showPopup);
              }}
            >
              <div className="d-flex d-justify-center d-align-center rounded-50 bg-sea-green">
                <img
                  src={`/assets/search-${`All`}.svg`}
                  className="image-cover rounded-50"
                  style={{ height: "30px", width: "30px" }}
                />
              </div>
              <div className="font-16">{selectedOption?.option}</div>
              <div className="d-flex d-justify-center d-align-center cursor-pointer">
                <img
                  src="/assets/arrow-down.svg"
                  className="image-cover"
                  style={{ height: "22px", width: "22px" }}
                />
              </div>

              {/* Options Popup */}

              {showPopup && options && (
                <div
                  className="p-absolute"
                  style={{ top: "120%", left: "0%", zIndex: "9" }}
                >
                  <OptionsPopup
                    setLoading={setLoading}
                    optionsList={options}
                    getSelectedOption={setSelectedOptionValue}
                  />
                </div>
              )}
            </div>

            {/* Search space */}
            <input
              className="col-12 col-md-9 pl-5 search-bar"
              placeholder="Search Products"
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>

          {/* search button */}

          <div
            className="d-flex cursor-pointer"
            role="button"
            onClick={submitHandler}
          >
            <img src="/assets/Search.svg" />
          </div>
          <span
            role="button"
            onClick={submitHandler}
            className="btn btn-sea-green"
          >
            Search
          </span>
        </div>
      </form>
    </>
  );
}

export default SearchBar;
