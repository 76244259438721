import React from "react";

function VerificationBadgeModal({ closeHandler }) {
  return (
    <div className="modal-wrapper d-flex d-align-center d-justify-center">
      <div className="d-flex d-flex-column bg-white col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4 gap-3 rounded-15 p-5">
        <div className="font-20 f-600 d-flex d-justify-center text-center mt-10">
          This verification badge represents that this company page is claimed.
        </div>

        <button className="w-full btn btn-aqua gap-1" onClick={closeHandler}>
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.4929 0.105713C4.70542 0.105713 0 4.69209 0 10.3332C0 15.9743 4.70542 20.5606 10.4929 20.5606C16.2804 20.5606 20.9858 15.9743 20.9858 10.3332C20.9858 4.69209 16.2804 0.105713 10.4929 0.105713Z"
              fill="white"
            />
            <path
              d="M8.47222 15.0109C7.13191 13.7045 5.7998 12.3862 4.45539 11.0798C4.30784 10.9359 4.30784 10.6962 4.45539 10.5524L6.00064 9.04625C6.1482 8.90243 6.39413 8.90243 6.54168 9.04625L8.75093 11.1996L14.4319 5.6584C14.5835 5.51458 14.8254 5.51458 14.977 5.6584L16.5264 7.16855C16.678 7.31637 16.678 7.55208 16.5264 7.69591L9.01326 15.0109C8.8657 15.1588 8.62387 15.1588 8.47222 15.0109Z"
              fill="#59CAEC"
            />
          </svg>
          I undestand
        </button>
      </div>
    </div>
  );
}

export default VerificationBadgeModal;
