import React from "react";
import { useState, useEffect, useRef } from "react";
import { Calendar } from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import { dateFormatter } from "../../../utils/userAgent";

function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

function FilterDropdown({
  arr,
  defaultVal,
  defaultDate,
  handler,
  customDateHandler,
  deleteHandler,
  multiselector,
}) {
  const [open, setOpen] = useState(false);
  const [calanderOpen, setCalanderOpen] = useState(false);
  const [selected, setSelected] = useState(defaultVal);
  const [customDate, setCustomDate] = useState(defaultDate);
  const wrapperRef = useRef(null);
  const calanderRef = useRef(null);
  const openHandler = () => setOpen((prev) => !prev);
  const calanderOpenHandler = () => setCalanderOpen((prev) => !prev);
  useOutsideAlerter(wrapperRef, openHandler);
  useOutsideAlerter(calanderRef, calanderOpenHandler);

  const handleItemClick = (item) => {
    handler(item);
    if (item?.text !== "Custom range") {
      setCustomDate({ from: null, to: null });
    }
    setSelected(item);
    if(!multiselector)openHandler();
  };

  const handleCalendarChange = (val) => {
    setCustomDate(val);
    customDateHandler(val);
  };

  useEffect(() => {
    setSelected(defaultVal);
    setCustomDate(defaultDate);
  }, [defaultVal, defaultDate]);

  useEffect(()=>{

  },[defaultVal, multiselector])

  const checkElementExists = (item) =>{
    
    let indexOfElement = "";

    let elementExists = defaultVal?.some((ele,ind) => {
      indexOfElement = ind;
      if(ele?.val === item?.val){
        return true
      }else{
        return false
      }
    })

    if(elementExists){
      deleteHandler(indexOfElement);
    }else{
      handleItemClick(item);
    }
  }

  return (
    <div className="p-relative">
      <div
        onClick={() => setOpen(true)}
        className="d-flex d-align-center d-justify-space-between border rounded-8 pl-3 pr-3 pt-2 pb-2"
      >
        <span className="h6 l-16 text-black">{selected?.text}</span>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 6L8 10L12 6"
            stroke="#1E1E1E"
            strokeWidth="1.6"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {open && (
        <div
          ref={wrapperRef}
          style={{ zIndex: "2" }}
          className="col-12 p-absolute d-flex d-flex-column bg-white box-shadow2 rounded-8 p-1"
        >
          {arr.map((item, index) => (
            <div
              onClick={() =>{
                multiselector
                  ? checkElementExists(item)
                  : handleItemClick(item);
              }}
              key={index}
              className="d-flex d-align-center d-justify-space-between cursor-pointer"
            >
              <span className="col-12 dropdown-items h6 f-500 l-20 text-black pl-3 pt-1 pb-1">
                {item?.text}
              </span>
              {multiselector && <input type="checkbox" checked={defaultVal?.some((ele) => ele?.val === item?.val)}/>}
            </div>
          ))}
        </div>
      )}
      {selected?.text === "Custom range" && (
        <div className="p-relative mt-3">
          <div
            onClick={() => setCalanderOpen(true)}
            className="d-flex d-align-center d-justify-space-between border rounded-8 pl-3 pr-3 pt-2 pb-2"
          >
            {customDate.from && customDate.to && (
              <span className="h6 l-16 text-black">
                {dateFormatter(
                  `${customDate?.from?.year}-${customDate?.from?.month}-${customDate?.from?.day}`
                )}{" "}
                to{" "}
                {dateFormatter(
                  `${customDate?.to?.year}-${customDate?.to?.month}-${customDate?.to?.day}`
                )}
              </span>
            )}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6L8 10L12 6"
                stroke="#1E1E1E"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {calanderOpen && (
            <div ref={calanderRef} className="p-absolute">
              <Calendar
                value={customDate}
                onChange={handleCalendarChange}
                calendarClassName={``}
                colorPrimary="var(--sea-green)"
                colorPrimaryLight="var(--c-green)"
              />
            </div>
          )}
        </div>
      )}
      {/* {multiselector && (
        <div className="d-flex d-flex-wrap gap-1 mt-3">
          {defaultVal &&
            Array.isArray(defaultVal) &&
            defaultVal?.map((item, index) => (
              <div
                key={item?.val}
                className="d-flex gap-1 bg-light-sea-green rounded d-align-center d-justify-center p-1 border"
              >
                <span className="font-16 text-sea-green">{item?.text}</span>
                <img
                  src="assets/cancel.svg"
                  alt="cancel-icon"
                  onClick={() => {
                    deleteHandler(index);
                  }}
                />
              </div>
            ))}
        </div>
      )} */}
    </div>
  );
}

export default FilterDropdown;
