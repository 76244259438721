import React, { useEffect, useState, useRef, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "../../../styles/modular css/NotificationModal.module.css";
import { getMethod } from "../../../utils/apiMethod";
import { toastError } from "../../../utils/Toasters";
import { StateContext } from "../../Context/StateContext";

function useOutsideAlerter(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, handler]);
}

function NotificationModal() {
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const {showDot} = useContext(StateContext);

  const wrapperRef = useRef(null);
  const location = useLocation();

  const getNotifications = async() => {
    try{
      let res = await getMethod(`notification/getMyNotification?limit=3`);
      setNotifications(res?.data?.results);
    }catch(error){
      toastError(error.message);
    }
  }

  useEffect(()=>{
    getNotifications();
  },[])

  const openHandler = () => setOpen((prev) => !prev);
  useOutsideAlerter(wrapperRef, openHandler);
  return (
    <div className={`${styles.modalWrapper} p-relative d-flex d-justify-end`}>
      <div onClick={() => setOpen(true)} className="p-relative">
        <span
          style={{ height: "100%" }}
          className="d-flex d-align-center p-4 rounded-15 bg-grey-1"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.7502 5.08384C13.9382 2.89591 16.9056 1.66675 19.9998 1.66675C23.094 1.66675 26.0615 2.89591 28.2494 5.08384C30.4373 7.27176 31.6665 10.2392 31.6665 13.3334C31.6665 18.9212 32.8616 22.3911 33.963 24.4103C34.516 25.4241 35.0544 26.0892 35.4285 26.484C35.6159 26.6819 35.7632 26.8131 35.8519 26.887C35.8963 26.924 35.9261 26.9467 35.9389 26.9562C35.9409 26.9577 35.9425 26.9589 35.9437 26.9598C36.5411 27.3702 36.8055 28.1209 36.595 28.8164C36.3821 29.5193 35.7343 30.0001 34.9998 30.0001H4.9998C4.26536 30.0001 3.61748 29.5193 3.40465 28.8164C3.19408 28.1209 3.45848 27.3702 4.05595 26.9598C4.05712 26.9589 4.05871 26.9577 4.06072 26.9562C4.07355 26.9467 4.1033 26.924 4.14767 26.887C4.23637 26.8131 4.38367 26.6819 4.57113 26.484C4.94518 26.0892 5.48365 25.4241 6.03664 24.4103C7.13803 22.3911 8.33314 18.9212 8.33314 13.3334C8.33314 10.2392 9.5623 7.27176 11.7502 5.08384ZM4.0728 26.9483C4.07293 26.9482 4.07306 26.9482 4.07319 26.9481C4.07319 26.9481 4.07318 26.9481 4.07317 26.9481L4.0728 26.9483ZM8.58201 26.6667H31.4176C31.2908 26.459 31.1635 26.239 31.0366 26.0065C29.638 23.4424 28.3331 19.4123 28.3331 13.3334C28.3331 11.1233 27.4552 9.00366 25.8924 7.44086C24.3296 5.87806 22.2099 5.00008 19.9998 5.00008C17.7897 5.00008 15.6701 5.87806 14.1072 7.44086C12.5444 9.00366 11.6665 11.1233 11.6665 13.3334C11.6665 19.4123 10.3616 23.4424 8.96296 26.0065C8.83613 26.239 8.70882 26.459 8.58201 26.6667Z"
              fill="#A39C9C"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.2809 33.5583C17.0771 33.0964 18.0969 33.3675 18.5588 34.1637C18.7053 34.4162 18.9156 34.6259 19.1686 34.7716C19.4216 34.9173 19.7085 34.994 20.0005 34.994C20.2925 34.994 20.5793 34.9173 20.8323 34.7716C21.0853 34.6259 21.2956 34.4162 21.4421 34.1637C21.904 33.3675 22.9239 33.0964 23.7201 33.5583C24.5163 34.0202 24.7873 35.04 24.3255 35.8362C23.8859 36.5939 23.2551 37.2229 22.496 37.6601C21.737 38.0973 20.8764 38.3274 20.0005 38.3274C19.1245 38.3274 18.2639 38.0973 17.5049 37.6601C16.7459 37.2229 16.115 36.5939 15.6755 35.8362C15.2136 35.04 15.4846 34.0202 16.2809 33.5583Z"
              fill="#A39C9C"
            />
          </svg>
        </span>
        {showDot&&<span
          style={{ width: "18px", height: "18px", top: "-4px", right: "-4px" }}
          className="p-absolute bg-sea-green rounded"
        ></span>}
      </div>
      {open && (
        <ul
          ref={wrapperRef}
          className={`${styles.popUp} p-absolute d-flex d-flex-column bg-white o-hidden`}
        >
          {notifications &&
            notifications?.map((item, index) => (
              <li
                key={item?.id}
                onClick={() => {
                  item.actionUrl && window.open(item?.actionUrl, "_self");
                }}
                className="d-flex d-align-center d-justify-space-between pt-5 pb-5 pr-2 pl-3 cursor-pointer"
              >
                <div className="col-10 d-flex d-align-center gap-1">
                  <div style={{ height: "30px" }}>
                    {item?.companyLogo ? (
                      <img
                        src={item?.companyLogo}
                        alt="company-logo"
                        style={{ objectFit: "contain", width: "auto" }}
                        height="100%"
                      />
                    ) : (
                      <img
                        src="/assets/Image-Not-Found.png"
                        alt="img-placeholder"
                        style={{ objectFit: "contain", width: "auto" }}
                        height="100%"
                      />
                    )}
                  </div>
                  <span className="h5 f-500 l-24 text-grey-11">
                    {item?.message}
                  </span>
                </div>
                <span className="h6 f-400 l-20 text-grey-12 text-center">
                  {new Date(item?.createdAt)?.toString()?.slice(0, 16)}
                </span>
              </li>
            ))}
          <Link
            state={{ from: location.pathname + location.search }}
            to={`/notifications`}
          >
            <div className="d-flex d-align-center d-justify-center h3 f-500 l-30 pt-2 pb-2">
              See all
            </div>
          </Link>
        </ul>
      )}
    </div>
  );
}

export default NotificationModal;
