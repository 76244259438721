import React from 'react'
import ClaimProfileWrapper from './ClaimProfileWrapper';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function PasswordResetSuccess() {
  return (
    <ClaimProfileWrapper>
      <div className="d-flex d-flex-column d-align-center gap-2">
        <span className="h2 f-500 l-36 text-black text-center">
          Password has been reset successfully.
        </span>
        <span className="h5 f-400 l-20 text-black">
          Login to you company profile
        </span>
        <a href="https://company.dev.futrconnect.com/" target="_self">
          <button className="btn btn-sea-green">Login</button>
        </a>
      </div>
      <ToastContainer/>
    </ClaimProfileWrapper>
  );
}

export default PasswordResetSuccess