import React from 'react'

function RegisterSuccessModal({onClickBackToLogin}) {
  return (
    <div
      className="modal-wrapper d-flex d-align-center d-justify-center"
    >
      <div className="d-flex-column bg-white col-11 col-sm-8 col-md-6 col-lg-5 col-xl-4 gap-5 rounded-15 p-5">
        <div className="font-20 f-600 d-flex d-justify-center text-center mt-10">
          You have been registered successfully!
        </div>

        <div className="d-flex d-justify-center w-full mt-10">
          <button
            className="btn btn-sea-green col-6"
            onClick={onClickBackToLogin}
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  )
}

export default RegisterSuccessModal