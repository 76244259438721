import moment from "moment-timezone";
import { postMethod } from "./apiMethod";
import { toastError } from "./Toasters";
import ReactGA from "react-ga4";

export const dateFormatter = (date) => {
  const formattedDate = moment(date, ["YYYY-M-D", "YYYY-MM-DD"]).format(
    "YYYY-MM-DD"
  );
  return formattedDate;
};

export const trackBookMeetingClick = async (companyId, meetingLink) => {
  // ReactGA.event({
  //   action: `Book_meeting_click?id=${companyId}`,
  //   category: "Button",
  //   label: "Book Meeting",
  //   value: companyId,
  // });

  try {
    var raw = JSON.stringify({
      company_id: companyId,
      meeting_link: meetingLink,
    });
    let response = await postMethod(`meetingLinkClick/log`, raw);
    console.log(response)
  } catch (error) {
    toastError(error.message)
  }
};
