import { createContext, useState } from "react";

export const StateContext = createContext();

const StateProvider = ({children}) => {

    const [loading, setLoading] = useState(false)
    const [firstLoading, setFirstLoading] = useState(false)
    const [followStatusChanged, setFollowStatusChanged] = useState(false);
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const [showDot, setShowDot] = useState(false);
    
    return <StateContext.Provider value={{loading, setLoading , followStatusChanged, 
        setFollowStatusChanged, showLogoutModal, setShowLogoutModal, firstLoading, setFirstLoading, showDot, setShowDot}}>{children}</StateContext.Provider> 
} 

export default StateProvider