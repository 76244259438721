import React, { useContext, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import LoggedInHeader from './general/LoggedInHeader'
import styles from "../styles/modular css/Notification.module.css"
import { useEffect } from 'react';
import { toastError } from '../utils/Toasters';
import { getMethod } from '../utils/apiMethod';
import Pagination from './general/Pagination';
import { StateContext } from './Context/StateContext';
import Footer from  "./general/Footer"

function Notification() {
  const navigate = useNavigate();
  const location = useLocation();
  const [notifications, setNotifications] = useState([]);
 const [activePage, setActivePage] = useState(1);
 const [limit, setLimit] = useState(12);
  const [totalResults, setTotalResults] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
 const { setLoading } = useContext(StateContext);

  const getNotifications = async () => {
    setLoading(true)
    try {
      let res = await getMethod(`notification/getMyNotification?page=${activePage}&limit=${limit}`);
      setNotifications(res?.data?.results);
      setTotalResults(res.data?.totalResults);
      setTotalPages(res.data?.totalPages);
      setActivePage(res.data?.page);
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toastError(error.message)
    }
  };

  useEffect(() => {
    getNotifications();
  }, [limit, activePage, totalPages]);

  const fetchActivePage = (page) => {
    setActivePage(page);
  };

  const fetchLimit = (newLimit) => {
    setLimit(newLimit);
  };
  
  const backHandler = () => {
    if (location.state?.from) {
      const url = new URL(location.state.from, window.location.origin);
      navigate(url.pathname + url.search); // Navigate with the same query parameters
    } else {
      navigate("/dashboard");
    }
  };
  
  return (
    <div className="d-flex d-flex-column gap-2">
      <LoggedInHeader />
      <div className="mt-170">
        <div className="container d-flex d-flex-column gap-2 col-12 col-sm-11 p-5">
          <div className="d-flex d-align-center gap-2">
            <img
              onClick={backHandler}
              src="/assets/green-arrow-back-circle.svg"
              alt="back-arrow"
              className="cursor-pointer"
            />
            <span className="h1 f-700 l-40 text-aqua">Notifications</span>
          </div>
          <div className="d-flex d-flex-column gap-2">
            <ul className="m-0 p-0 d-flex d-flex-column gap-1">
              {notifications &&
                notifications?.map((item, index) => (
                  <li
                    key={item?.id}
                    className={`${styles["list-item"]} d-flex d-align-center d-justify-space-between gap-2 pl-5 pr-5 pt-3 pb-3 border-bottom-dark`}
                  >
                    <div className="d-flex d-align-center gap-2">
                      <div style={{ height: "48px" }}>
                        {item?.companyLogo ? (
                          <img
                            src={item?.companyLogo}
                            alt="company-logo"
                            style={{ objectFit: "contain", width: "auto" }}
                            height="100%"
                          />
                        ) : (
                          <img
                            src="/assets/Image-Not-Found.png"
                            alt="img-placeholder"
                            style={{ objectFit: "contain", width: "auto" }}
                            height="100%"
                          />
                        )}
                      </div>
                      <span className="h3 f-500 l-20 text-black">
                        {item?.message}
                      </span>
                    </div>
                    <button
                      onClick={() => {
                        item.actionUrl && window.open(item?.actionUrl, "_self");
                      }}
                      className="btn btn-sea-green"
                    >
                      Check out
                    </button>
                  </li>
                ))}
            </ul>
            <Pagination
              fetchActivePage={fetchActivePage}
              defaultLimit={12}
              fetchLimit={fetchLimit}
              totalPages={totalPages}
              records={totalResults}
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Notification