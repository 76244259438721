import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { unSecurePostMethod } from "../utils/apiMethod";
import { toastError, toastSuccess } from "../utils/Toasters";
import ClaimProfileWrapper from "./claimProfile/ClaimProfileWrapper";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  let query = new URLSearchParams(useLocation().search);
  let token = query.get("token");

  const submitHandler = async (e) => {
    e.preventDefault();
    if (password?.trim() == confirmPassword?.trim()) {
      setBtnLoad(true);
      try {
        var raw = {
          token: token, //derived from url
          newPassword: password?.trim(),
        };
        let response = await unSecurePostMethod(`auth/resetEmailPassword`, raw);
        if (response.status == true) {
          toastSuccess("Reset Password Successfull! Redirecting to login...");
          setTimeout(() => {
            window.open("/", "_self");
          }, 3000);
        }
        setBtnLoad(false);
      } catch (error) {
        setBtnLoad(false);
        toastError(error?.message);
      }
    } else {
      toastError("Passwords do not match.");
    }
  };
  return (
    <ClaimProfileWrapper>
      <form
        onSubmit={submitHandler}
        className="col-6 d-flex d-flex-column gap-5"
      >
        <div className="d-flex d-flex-column gap-3">
          <div className="d-flex d-flex-column d-align-center gap-1">
            <span className="h2 f-500 l-36 text-black">Reset Password</span>
            <span className="h5 f-400 l-20 text-black">
              Create a password for your profile.
            </span>
          </div>
          <div className="d-flex d-flex-column gap-1">
            <span className="h5 f-400 l-22 text-black">New Password</span>
            <div className="d-flex d-align-center border rounded-8 pl-4 pt-3 pb-3 pr-4 bg-white">
              <input
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={`${showPassword ? "text" : "password"}`}
                placeholder="New Password"
                className="col-12 border-none"
              />
              {showPassword ? (
                <svg
                  className="cursor-pointer"
                  onClick={() => setShowPassword((prev) => !prev)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L14 14M6.56285 6.6091C6.21442 6.96903 6 7.45948 6 8C6 9.10457 6.89543 10 8 10C8.54832 10 9.0451 9.77935 9.4064 9.42199M4.33333 4.43143C3.06713 5.26689 2.10269 6.52263 1.63867 7.99998C2.48817 10.7047 5.01504 12.6667 8.00014 12.6667C9.32607 12.6667 10.5616 12.2796 11.5999 11.6123M7.33333 3.36626C7.55265 3.34448 7.77509 3.33333 8.00013 3.33333C10.9852 3.33333 13.5121 5.29528 14.3616 8.00002C14.1744 8.59598 13.9058 9.15588 13.5688 9.66667"
                    stroke="#131A29"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className="cursor-pointer"
                  onClick={() => setShowPassword((prev) => !prev)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99984 7.99998C9.99984 9.10455 9.10441 9.99998 7.99984 9.99998C6.89527 9.99998 5.99984 9.10455 5.99984 7.99998C5.99984 6.89541 6.89527 5.99998 7.99984 5.99998C9.10441 5.99998 9.99984 6.89541 9.99984 7.99998Z"
                    stroke="#131A29"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.00013 3.33331C5.01504 3.33331 2.48819 5.29523 1.63867 7.99996C2.48817 10.7047 5.01504 12.6666 8.00014 12.6666C10.9852 12.6666 13.5121 10.7047 14.3616 8C13.5121 5.29526 10.9852 3.33331 8.00013 3.33331Z"
                    stroke="#131A29"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
          <div className="d-flex d-flex-column gap-1">
            <span className="h5 f-400 l-22 text-black">Confirm Password</span>
            <div className="d-flex d-align-center border rounded-8 pl-4 pt-3 pb-3 pr-4 bg-white">
              <input
                required
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={`${showConfirmPassword ? "text" : "password"}`}
                placeholder="Confirm Password"
                className="col-12 border-none"
              />
              {showConfirmPassword ? (
                <svg
                  className="cursor-pointer"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L14 14M6.56285 6.6091C6.21442 6.96903 6 7.45948 6 8C6 9.10457 6.89543 10 8 10C8.54832 10 9.0451 9.77935 9.4064 9.42199M4.33333 4.43143C3.06713 5.26689 2.10269 6.52263 1.63867 7.99998C2.48817 10.7047 5.01504 12.6667 8.00014 12.6667C9.32607 12.6667 10.5616 12.2796 11.5999 11.6123M7.33333 3.36626C7.55265 3.34448 7.77509 3.33333 8.00013 3.33333C10.9852 3.33333 13.5121 5.29528 14.3616 8.00002C14.1744 8.59598 13.9058 9.15588 13.5688 9.66667"
                    stroke="#131A29"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  className="cursor-pointer"
                  onClick={() => setShowConfirmPassword((prev) => !prev)}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99984 7.99998C9.99984 9.10455 9.10441 9.99998 7.99984 9.99998C6.89527 9.99998 5.99984 9.10455 5.99984 7.99998C5.99984 6.89541 6.89527 5.99998 7.99984 5.99998C9.10441 5.99998 9.99984 6.89541 9.99984 7.99998Z"
                    stroke="#131A29"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.00013 3.33331C5.01504 3.33331 2.48819 5.29523 1.63867 7.99996C2.48817 10.7047 5.01504 12.6666 8.00014 12.6666C10.9852 12.6666 13.5121 10.7047 14.3616 8C13.5121 5.29526 10.9852 3.33331 8.00013 3.33331Z"
                    stroke="#131A29"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
        {btnLoad ? (
          <div role="button" className="btn btn-sea-green">
            Loading <PulseLoader loading={btnLoad} size={8} color="#FFFFFF" />
          </div>
        ) : (
          <button type="submit" className="btn btn-sea-green">
            Submit
          </button>
        )}
      </form>
      <ToastContainer />
    </ClaimProfileWrapper>
  );
}

export default UserResetPassword;
