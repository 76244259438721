import LoginForm from "./LoginForm";
import Header from "./general/Header";
import Footer from "./general/Footer";
import styles from "../styles/modular css/Login.module.css";
import { Link, useNavigate } from "react-router-dom";
import { getToken } from "../auth/cookies";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function HomePage() {
  const navigate = useNavigate()
  const divStyle = {
    background: 'url("/assets/FutrTv.png"), lightgray 50% / cover no-repeat',
    height: "100%",
  };

  useEffect(() => {
    const token = getToken();
    if (token && token!=null && token!=undefined) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <div className="d-flex d-flex-column mt-170">
      <Header />
      {/* Section - 1 : Sign In form */}
      <div
        className="d-flex d-flex-wrap bg-white container col-11"
        id="loginForm"
      >
        <LoginForm />

        <div className="d-flex flex-1 d-justify-center d-align-center">
          <img
            src="/assets/ImageHeader1.png"
            alt="Image Header"
            className="col-12"
            style={{
              objectFit: "contain",
              maxWidth: "100%",
              minWidth: "300px",
            }}
          />
        </div>
      </div>

      {/* Section -2 : Trusted By */}
      <div className="m-top-100">
        <div className="bg-white container col-11">
          <div
            className={`bg-yellow2 rounded-40 d-flex d-flex-column d-justify-center m-auto p-2 ${styles.trustedByWrapper}`}
          >
            <div className="text-blue text-center font-40 f-900">
              Trusted by
            </div>
            <div className="row d-justify-center">
              <img src="/assets/Logos1.png" className="col-10" />
              <img src="/assets/Logos2.png" className="col-9" />
            </div>
          </div>
        </div>
      </div>

      {/* Section -3 : Reimagining the culture of tech */}
      <div className="m-top-100">
        <div
          className={`bg-white d-justify-space-between w-full gap-4 container col-11 ${styles.techCultureWrapper}`}
        >
          <div className="flex-1 d-flex d-flex-column gap-4 d-justify-center">
            <div className="blue-heading f-700">
              Reimagining the culture of tech
            </div>

            <div
              className={`d-flex d-flex-column gap-3 col-11 d-justify-center ${styles.techCultureContent}`}
            >
              <div>
                <p className="text-blue font-24 f-400 l-36">
                  futr Connect is the tech industry’s most progressive
                  centralized source to connect great ideas and great people at
                  great companies with the power of content, data and
                  collaboration.
                </p>
              </div>

              <a
                href="#loginForm"
                className="btn bg-aqua text-white btn-rounded font-20 f-700 l-32 cursor-pointer"
              >
                <button className="font-20 f-700 l-32 bg-none border-none text-white cursor-pointer">
                  Join now
                </button>
              </a>
            </div>
          </div>

          <div
            className="d-flex d-justify-center d-align-center m-inline-auto"
            style={{ minWidth: "300px" }}
          >
            <img
              src="/assets/CultureOfTech.png"
              alt="Culture of tech"
              className="col-12 image-contain"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                minWidth: "100%",
              }}
            />
          </div>
        </div>
      </div>

      {/* Section -4 : Who Futr is for */}
      <div
        style={{
          background: "linear-gradient(180deg, #F5F5F5 0%, #EEF9FB 94.91%)",
        }}
      >
        <div
          id={"aboutFutr"}
          className={`${styles.futrForWrapper} container col-11`}
        >
          <div
            className="d-flex d-justify-center d-align-center m-inline-auto"
            style={{ minWidth: "300px" }}
          >
            <img
              src="/assets/WhoFutrIsFor.png"
              alt="Culture of tech"
              className="col-12 image-contain"
              style={{
                objectFit: "contain",
                maxWidth: "100%",
                minWidth: "100%",
              }}
            />
          </div>

          <div className="d-flex d-flex-column gap-4 d-justify-center">
            <div className="blue-heading f-700">Who futr is for</div>

            <div
              className={`d-flex d-flex-column gap-3 col-11 d-justify-center ${styles.techCultureContent}`}
            >
              <div>
                <p className="text-blue font-24 f-400 l-36">
                  futr Connect bridges tech innovators with businesses seeking
                  the latest solutions. Streamlining the discovery process, it
                  emphasizes direct company-to-company connections. It's the
                  nexus for those aiming for swift tech advancements and
                  partnerships.
                </p>
              </div>
              <a
                href="#loginForm"
                className="btn bg-aqua text-white btn-rounded font-20 f-700 l-32 cursor-pointer"
              >
                <button className="font-20 f-700 l-32 bg-none border-none text-white cursor-pointer">
                  Join now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Section - 5 : Spotlight on innovation */}
      <div className="m-top-100">
        <div
          id="spotlight"
          className={`row d-flex-column d-align-center text-blue bg-white container col-11 ${styles.spotlightWrapper}`}
        >
          <div className="font-32 f-700">Spotlight on innovation</div>

          <div className="d-flex d-flex-column d-align-center gap-5">
            <div className="font-72 f-700">
              Futr Connect’s featured Tech Showcase
            </div>
            <div className="text-center col-8 col-xs-12 font-24 l-36 f-400">
              Dive deep into FUTR Connect's specially curated showcase,
              highlighting the crème de la crème of the tech world. Discover
              breakthrough solutions, pioneering products, and the dynamic
              companies driving the future of technology. It's not just a
              showcase; it's a glimpse into the future of tech innovation.
            </div>
          </div>

          <div className="row col-10 col-xs-12 d-justify-space-around">
            <div className="d-flex d-justify-center">
              <img src="/assets/Spotlight1.png" alt="" />
            </div>
            <div className="d-flex d-justify-center">
              <img src="/assets/Spotlight2.png" alt="" />
            </div>
            <div className="d-flex d-justify-center">
              <img src="/assets/Spotlight3.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      {/* Section - 6 : As seen on futr tv */}
      <div className="m-top-100">
        <div
          style={{
            background: "linear-gradient(180deg, #F5F5F5 0%, #EEF9FB 94.91%)",
            paddingBlock: "100px",
          }}
        >
          <div
            className={`row d-justify-space-between w-full gap-4 container col-11 ${styles.techCultureWrapper}`}
          >
            <div className="flex-1 d-flex d-flex-column gap-4 d-justify-center">
              <div>
                <div className="f-700 font-32 text-blue">
                  {" "}
                  As seen on futr.tv
                </div>

                <div className="blue-heading f-700">
                  {" "}
                  Startups, Innovation, Culture & The Business Of Emerging Tech
                </div>
              </div>

              <div
                className={`d-flex d-flex-column gap-3 col-11 d-justify-center ${styles.techCultureContent}`}
              >
                <div>
                  <p className="text-blue font-24 f-400 l-36">
                    futr.tv focuses on startups, innovation, culture and the
                    business of emerging tech with weekly video and audio
                    podcasts where Chris Brandt and Sandesh Patel talk with
                    industry leaders and deep thinkers.
                  </p>
                </div>
                <Link
                  to="https://futr.tv/"
                  target="_blank"
                  className="btn bg-aqua text-white btn-rounded font-20 f-700 l-32 cursor-pointer"
                >
                  <button className="text-white font-20 f-700 l-32 bg-none border-none cursor-pointer">
                    Check Out futr.tv
                  </button>
                </Link>
              </div>
            </div>

            <div
              className="flex-1 d-flex d-justify-center d-align-center m-inline-auto"
              style={{ minWidth: "300px" }}
            >
              <img
                src="/assets/FutrTv.png"
                alt="futr tv"
                className="image-contain"
                style={{ objectFit: "contain", maxWidth: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Section - 7 : Benefits of Futr Connect */}

      <div className="bg-yellow2" style={{ paddingBlock: "100px" }}>
        <div className="d-flex d-flex-column text-blue container col-11">
          <div className={`font-24 f-700 l-36 ${styles.benefitsWrapper}`}>
            The benefits of futr Connect
          </div>

          <div className={`row col-12 gap-5 ${styles.benefitsWrapper}`}>
            <div
              className="flex-1 d-flex d-flex-column gap-3"
              style={{ minWidth: "320px" }}
            >
              <div className="font-40 l-57-6 f-700">For Tech Companies:</div>
              <div className="font-24 f-400 l-48">
                Amplified exposure to potential buyers. Direct connections with
                interested businesses. Streamlined showcasing of products and
                solutions. Opportunity to receive immediate feedback and
                insights. Enhanced reputation through a curated platform.
              </div>
            </div>

            <div
              className="flex-1  d-flex d-flex-column gap-3"
              style={{ minWidth: "320px" }}
            >
              <div className="font-40 l-57-6 f-700">
                For Those That Seek Tech:
              </div>
              <div className="font-24 f-400 l-48">
                Direct access to cutting-edge tech solutions. Simplified
                discovery process without the usual sales clutter. Authentic
                interactions with tech innovators. Insight into the latest
                industry trends and innovations. Efficient matching based on
                specific tech needs.
              </div>
            </div>
            {/* 
            <div className="col-6"></div> */}
          </div>
        </div>
      </div>

      <Footer btnLink="#loginForm" />
      <ToastContainer/>
    </div>
  );
}

export default HomePage;
