import React from 'react'
import { marked } from 'marked';

function MarkdownRenderer({markdown}) {
    // Convert Markdown to HTML
  const htmlContent = marked(markdown);
  return (
    <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
  )
}

export default MarkdownRenderer