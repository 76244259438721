import React, { useState } from "react";
import ReactPlayer from "react-player";
import { getMethod, postMethod } from "../../../../utils/apiMethod";
import { FollowUser } from "../../icons/Icons";
import ConfirmationModal from "../../Modals/ConfirmationModal";
import { toastSuccess, toastError } from "../../../../utils/Toasters";
import { useContext } from "react";
import { StateContext } from "../../../Context/StateContext";
import { Score } from "../../icons/Icons";
import { BookMeeting } from "../../icons/Icons";
import { Link, useLocation } from "react-router-dom";
import ModalWrapper from "../../Modals/ModalWrapper";
import VideoModal from "../../Modals/VideoModal";
import styles from "../../../../styles/modular css/TopTenCompanyCard.module.css";
import Cookies from "js-cookie";
import { trackBookMeetingClick } from "../../../../utils/userAgent";
import VerificationBadgeModal from "../../Modals/VerificationBadgeModal";

function TopTenCompanyCard({
  video,
  name,
  image,
  rating,
  employeeCount,
  description,
  id,
  followed,
  score,
  index,
  classes,
  setRefresh,
  meetingLink,
  categories,
  isVerified,
}) {
  const [status, setStatus] = useState(followed ? "Unfollow" : "Follow");
  const [scored, setScored] = useState(score);
  const [showModal, setShowModal] = useState(false);
  const { setFollowStatusChanged } = useContext(StateContext);
  const [openModal, setOpenModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const openModalHandler = () => setOpenModal((prev) => !prev);
  const location = useLocation();

  const handleFollwUnfollowApi = () => {
    if (status === "Follow") {
      let fcmToken = Cookies.get("fcm");
      var raw = JSON.stringify({
        fcmToken: fcmToken || null,
      });
      postMethod(`users/followUnfollowCompany/${id}`, raw)
        .then((res) => {
          if (res?.status) {
            // If currently in "follow" state, change to "following" for 1 second
            setStatus("Following");
            setTimeout(() => {
              setStatus("Unfollow");
            }, 1000);
            toastSuccess(res.message);

            setFollowStatusChanged(true);
          }
        })
        .catch((error) => {
          toastError(error.message);
          console.log("error.message: ", error.message);
        });
    } else if (status === "Unfollow") {
      // If currently in "unfollow" state, show modal to confirm
      setShowModal(true);
      document.body.style.overflowY = "hidden";
    }
  };
  const handleModalYes = () => {
    // setLoading(true);
    let fcmToken = Cookies.get("fcm");
    var raw = JSON.stringify({
      fcmToken: fcmToken || null,
    });
    postMethod(`users/followUnfollowCompany/${id ? id : 14}`, raw)
      .then((res) => {
        if (res?.status) {
          setStatus("Follow");
          setShowModal(false);
          document.body.style.overflowY = "unset";
          toastSuccess(res.message);

          setFollowStatusChanged(true);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toastError(error.message);
        console.log("error.message: ", error.message);
      });
  };

  const handleModalNo = () => {
    setShowModal(false);
    document.body.style.overflowY = "unset";
  };
  return (
    <>
      <Link
        state={{ from: location.pathname + location.search }}
        to={`/companyProfile/${id}#Products`}
      >
        <div
          className={`${styles.card} col-12 d-flex d-justify-space-between gap-4 p-5 border rounded`}
        >
          <div className="col-12 col-md-3">
            {video ? (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openModalHandler();
                }}
                className="p-relative rounded-top-12"
                style={{ width: "100%", height: "100%" }}
              >
                <ReactPlayer url={video} width="100%" height="100%" />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    left: "0",
                  }}
                  className="d-flex d-align-center d-justify-center"
                >
                  <img
                    className="cursor-pointer"
                    src="/assets/playIcon.svg"
                    alt="play-icon"
                  />
                </div>
              </div>
            ) : (
              <img
                style={{ objectFit: "cover" }}
                width="100%"
                height={179}
                src="/assets/videoThumbnail.png"
                alt="company video"
                className="rounded-8"
              />
            )}
          </div>
          <div className="col-12 col-md-6 col-xl-7 d-flex d-flex-column d-justify-center gap-1">
            <div className="d-flex d-flex-column gap-1">
              <div className="d-flex d-align-center gap-2">
                <div style={{ height: "34px" }}>
                  <img
                    style={{ objectFit: "contain" }}
                    height="100%"
                    src={`${image ? image : "/assets/Image-Not-Found.png"}`}
                    alt={name}
                  />
                </div>
                {/* {employeeCount > 0 && (
                <span className="h6 f-500 l-20 text-black-2">
                  <span className="h6 font-600">{employeeCount}+ </span>
                  Employees
                </span>
              )} */}
                <span className="font-12 f-600 l-18 text-black-2 text-ellipsis-1">
                  {name}
                </span>
                {isVerified && (
                  <div className="d-flex d-align-end">
                    <img
                      src="/assets/VerificationBadge.svg"
                      alt="verification-badge"
                      width={32}
                      height={32}
                      style={{ objectFit: "contain" }}
                    />

                    <img
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowVerificationModal(true);
                      }}
                      src="/assets/infoIcon.svg"
                      className="cursor-pointer"
                      alt="info-icon"
                      width={18}
                      height={18}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex d-flex-wrap gap-1 mt-1">
              {categories?.map((item, index) => (
                <div
                  key={index + 1}
                  className="d-flex gap-1 bg-light-sea-green rounded d-align-center d-justify-center p-1 border"
                >
                  <div className="bg-sea-green rounded d-flex d-justify-center d-align-center">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="15" cy="15" r="15" fill="#1CBAAB" />
                      <g clip-path="url(#clip0_1033_6854)">
                        <path
                          d="M22.5 14.375C22.5 13.7596 22.1628 13.2281 21.6667 12.9396V8.95859C21.6667 8.73073 21.4849 8.125 20.8333 8.125C20.6479 8.125 20.4638 8.18698 20.313 8.30781L18.0987 10.0794C16.9865 10.9685 15.5901 11.4583 14.1667 11.4583H9.16667C8.24609 11.4583 7.5 12.2044 7.5 13.125V15.625C7.5 16.5456 8.24609 17.2917 9.16667 17.2917H10.0443C10.0081 17.5646 9.9875 17.8422 9.9875 18.125C9.9875 19.1607 10.2286 20.1393 10.6531 21.0141C10.7883 21.2924 11.0833 21.4583 11.3927 21.4583H13.3271C14.0055 21.4583 14.4128 20.6813 14.0016 20.1417C13.5745 19.5813 13.3206 18.8823 13.3206 18.125C13.3206 17.8357 13.3628 17.5576 13.4354 17.2917H14.1667C15.5901 17.2917 16.9865 17.7815 18.0984 18.6706L20.3128 20.4422C20.4604 20.5604 20.6439 20.6248 20.8331 20.625C21.482 20.625 21.6664 20.0318 21.6664 19.7917V15.8107C22.1628 15.5219 22.5 14.9904 22.5 14.375ZM20 18.0578L19.1393 17.3693C17.7331 16.2443 15.9667 15.625 14.1667 15.625V13.125C15.9667 13.125 17.7331 12.5057 19.1393 11.3807L20 10.6922V18.0578Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1033_6854">
                          <rect
                            width="15"
                            height="15"
                            fill="white"
                            transform="translate(7.5 7.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className="h6 text-sea-green">{item?.name}</div>
                </div>
              ))}
            </div>
            <p className="m-0 text-ellipsis-2 text-left text-black-2">
              {description}
            </p>
          </div>
          <div
            className={`${styles.btnWrapper} col-12 col-md-3 col-xl-2 d-flex d-flex-column d-justify-center gap-2`}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleFollwUnfollowApi();
              }}
              className={`btn col-12 d-flex d-align-center gap-1 ${
                status === "Unfollow" ? "btn-white-thin" : "btn-aqua-thin"
              }`}
            >
              {status === "Follow" && <FollowUser />}

              {status}
            </button>
            <button
              //   className={`btn btn-aqua-thin col-12 d-flex d-align-center gap-1 btn-disable-score
              //     ${scored ? "cursor-disabled" : ""}
              // `}
              className={`btn btn-aqua-thin col-12 d-flex d-align-center gap-1 btn-disable-score`}
              // onClick={() => (scored ? "" : setShowScoreModal(true))}
            >
              <Score />
              {scored ? "Scored" : "Score"}
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                trackBookMeetingClick(id, meetingLink || "");
                meetingLink
                  ? window.open(meetingLink, "_blank")
                  : toastError("No meeting link found for this company!");
              }}
              className="btn btn-sea-green-thin col-12 d-flex d-align-center gap-1"
            >
              <BookMeeting />
              Book Meeting
            </button>
          </div>
        </div>
      </Link>
      {showModal && (
        <div>
          <ConfirmationModal onYes={handleModalYes} onNo={handleModalNo} />
        </div>
      )}
      {openModal && (
        <ModalWrapper>
          <VideoModal
            url={video}
            closeHandler={() => setOpenModal(false)}
          ></VideoModal>
        </ModalWrapper>
      )}
      {showVerificationModal && (
        <ModalWrapper>
          <VerificationBadgeModal
            closeHandler={() => setShowVerificationModal(false)}
          />
        </ModalWrapper>
      )}
    </>
  );
}

export default TopTenCompanyCard;
