import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { unSecureGetMethod, unSecurePostMethod } from "../../utils/apiMethod";
import { toastError, toastSuccess } from "../../utils/Toasters";
import ClaimProfileWrapper from "./ClaimProfileWrapper";
import { StateContext } from "../Context/StateContext";
import LinkExpired from "./LinkExpired";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UserClaim() {
  const [isLinkExpired, setLinkExpired] = useState(null);
  const [ownerName, setOwnerName] = useState("");
  const [title, setTitle] = useState("");
  const [email, setEmail] = useState("");
  const [btnLoad, setBtnLoad] = useState(false);
  const { setLoading } = useContext(StateContext);
  let query = new URLSearchParams(useLocation().search);
  let requestId = query.get("requestId");

  const validateLink = async () => {
    setLoading(true);
    if (requestId) {
      try {
        let response = await unSecureGetMethod(`auth/checkExpiry/${requestId}`);
        if (response.status == true) {
          setLoading(false);
          setLinkExpired(false);
        } else {
          setLinkExpired(true);
          setLoading(false);
        }
      } catch (error) {
        toastError(error?.message);
      }
    } else {
      toastError("Request id not found");
    }
  };

  useEffect(() => {
    validateLink();
  }, []);

  const ownerNameHandler = (e) => setOwnerName(e.target.value);
  const titleHandler = (e) => setTitle(e.target.value);
  const emailHandler = (e) => setEmail(e.target.value);

  const submitHandler = async (e) => {
    e.preventDefault();
    setBtnLoad(true);
    try {
      var raw = {
        requestId: requestId,
        email: email,
        name: ownerName,
        title: title,
      };
      let response = await unSecurePostMethod(`auth/submitBasicForm`, raw);
      if (response.status == true) {
        toastSuccess(response?.message);
      }
      setBtnLoad(false);
    } catch (error) {
      setBtnLoad(false);
      toastError(error?.message);
    }
  };
  return (
    <ClaimProfileWrapper>
      {isLinkExpired == false && (
        <form onSubmit={submitHandler} className="d-flex d-flex-column gap-5">
          <div className="d-flex d-flex-column gap-3">
            <div className="d-flex d-flex-column gap-1">
              <span className="h2 f-500 l-36 text-black">
                Add following details to claim profile.
              </span>
              <span className="h5 f-400 l-20 text-black">
                Fill out the remaining fields.
              </span>
            </div>
            <div className="d-flex d-flex-column gap-1">
              <span className="h5 f-400 l-22 text-black">
                Profile Owner Name
              </span>
              <input
                required
                value={ownerName}
                onChange={ownerNameHandler}
                type="text"
                placeholder="Joe Root"
                className="border rounded-8 pl-4 pt-3 pb-3 pr-4"
              />
            </div>
            <div className="d-flex d-flex-column gap-1">
              <span className="h5 f-400 l-22 text-black">
                Position or Title
              </span>
              <input
                required
                value={title}
                onChange={titleHandler}
                type="text"
                placeholder="CEO"
                className="border rounded-8 pl-4 pt-3 pb-3 pr-4"
              />
            </div>
            <div className="d-flex d-flex-column gap-1">
              <span className="d-flex d-align-center h5 f-400 l-22 text-black">
                Email
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00016 14.6666C4.31826 14.6666 1.3335 11.6818 1.3335 7.99998C1.3335 4.31808 4.31826 1.33331 8.00016 1.33331C11.682 1.33331 14.6668 4.31808 14.6668 7.99998C14.6668 11.6818 11.682 14.6666 8.00016 14.6666ZM8.00016 13.3333C10.9457 13.3333 13.3335 10.9455 13.3335 7.99998C13.3335 5.05446 10.9457 2.66665 8.00016 2.66665C5.05464 2.66665 2.66683 5.05446 2.66683 7.99998C2.66683 10.9455 5.05464 13.3333 8.00016 13.3333ZM7.3335 4.66665H8.66683V5.99998H7.3335V4.66665ZM7.3335 7.33331H8.66683V11.3333H7.3335V7.33331Z"
                    fill="black"
                  />
                </svg>
              </span>
              <input
                required
                value={email}
                onChange={emailHandler}
                type="email"
                placeholder="joe@apple.com"
                className="border rounded-8 pl-4 pt-3 pb-3 pr-4"
              />
            </div>
          </div>
          {btnLoad ? (
            <div role="button" className="btn btn-sea-green">
              Loading <PulseLoader loading={btnLoad} size={8} color="#FFFFFF" />
            </div>
          ) : (
            <button type="submit" className="btn btn-sea-green">
              Submit
            </button>
          )}
        </form>
      )}
      {isLinkExpired == true && <LinkExpired />}
      <ToastContainer />
    </ClaimProfileWrapper>
  );
}

export default UserClaim;
