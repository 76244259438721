import React from "react";
import { getToken } from "../auth/cookies";
import styles from "../styles/modular css/NotFound.module.css";
import Footer from "./general/Footer";
import Header from "./general/Header";
import LoggedInHeader from "./general/LoggedInHeader";

function NotFound() {
  var token = getToken();
  return (
    <div className={`${styles.wrapper} d-flex d-flex-column`}>
      {token && token != undefined ? <LoggedInHeader /> : <Header />}
      <div
        className={`${styles.container} d-flex d-align-center d-justify-center`}
      >
        <img className="col-11 col-sm-9 col-md-7 col-lg-7 col-xxl-6" src="/assets/404.png" alt="404" />
      </div>
      <Footer />
    </div>
  );
}

export default NotFound;
