import firebase from "firebase/compat/app";
import "firebase/compat/auth";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)

// Check if Firebase has already been initialized (important for development hot reloading)
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  console.log("Firebase app already initialized");
}

// Export Firebase app
export default firebase;
