import React, { useState, useEffect } from "react";

function Pagination({ fetchActivePage, fetchLimit, totalPages, records, defaultLimit }) {
  const [activePage, setActivePage] = useState(1); // Default active page to 1
  const [limit, setLimit] = useState(defaultLimit??4); // Initialize with fetchLimit
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  useEffect(() => {
    // Fetch the active page data whenever activePage changes
    fetchActivePage(activePage);
    // fetchLimit(limit)
  }, [activePage]);
  useEffect(() => {
    fetchLimit(limit);
  }, [limit]);

  const handlePageClick = (page) => {
    setActivePage(page);
    fetchActivePage(page);
  };

  const handlePrevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const handleNextPage = () => {
    if (activePage < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectLimit = (newLimit) => {
    setLimit(newLimit);
    setIsDropdownOpen(false); // Close the dropdown after selection
  };

  return (
    <div className="d-flex d-justify-space-between d-align-center d-flex-wrap gap-2">
      <div className="d-flex text-grey-10">
        <div className="d-flex gap-2 d-align-center">
          <div className="font-14 text-grey-17">View</div>
          <div className="p-relative" onClick={toggleDropdown}>
            <div className="d-flex gap-2 d-align-center font-14 border-grey bg-white cursor-pointer rounded-10 p-3">
              {limit}
              <img src="/assets/Dropdown.svg" />
            </div>

            {isDropdownOpen && (
              <div
                className="p-absolute bg-white box-shadow1 rounded-5 col-12 text-center"
                style={{ zIndex: "1" }}
              >
                {[4, 8, 10, 12].map((option) => (
                  <div
                    key={option}
                    className="font-16 p-2 cursor-pointer"
                    onClick={() => {
                      selectLimit(option);
                      setActivePage(1);
                    }}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="font-14 text-grey-17">Products per page</div>
        </div>
      </div>
      {/* <div className="font-14 text-grey-17">
        Showing{" "}
        {activePage * limit - limit + 1 <= records
          ? activePage * limit - limit + 1
          : 0}{" "}
        to {Math.min(activePage * limit, records)} out of {records} records
      </div> */}

      <div className="d-flex d-align-center gap-1">
        <div className="d-flex d-align-center" onClick={handlePrevPage}>
          <img
            src="/assets/arrow-left.svg"
            style={{ height: "24px", width: "24px", cursor: "pointer" }}
          />
        </div>
        <div className="d-flex font-14 gap-1">
          {Array.from({ length: totalPages }, (_, index) => (
            <div
              key={index + 1}
              className={`font-16 pt-2 pb-2 pl-4 pr-4 bg-white ${
                activePage === index + 1
                  ? "rounded-10 border border-grey-17"
                  : "rounded-50 "
              }`}
              style={{ cursor: "pointer" }}
              onClick={() => handlePageClick(index + 1)}
            >
              {index + 1}
            </div>
          ))}
        </div>
        <div className="d-flex d-align-center" onClick={handleNextPage}>
          <img
            src="/assets/arrow-right.svg"
            style={{ height: "24px", width: "24px", cursor: "pointer" }}
          />
        </div>
      </div>
    </div>
  );
}

export default Pagination;

// import React, { useState } from 'react'

// function Pagination({fetchActivePage, fetchLimit, pages, records }) {
//     const [activePage, setActivePage] = useState(0);
//     const [limit, setLimit] = useState(0);

//     const [totalPages, setTotalPages] = useState(0);
//     const [totalRecords, setTotalRecords] = useState(0);

//   return (
//     <div className="d-flex d-justify-space-between d-align-center">
//     <div className="d-flex text-grey-10">
//     <div className="d-flex gap-2 d-align-center">
//         <div className="font-14 text-grey-17">Showing</div>
//         <div className="d-flex gap-2 d-align-center font-14 border-grey bg-white rounded-10 p-3">
//         08
//         <img src="/assets/Dropdown.svg" />
//         </div>
//     </div>
//     </div>
//     <div className="font-14 text-grey-17">Showing 1 to 10 out of 60 records</div>

//     <div className="d-flex d-align-center gap-1">
//         <div className="d-flex d-align-center">
//             <img src="/assets/arrow-left.svg" style={{height:"24px", width:"24px"}}/>
//         </div>
//         <div className="d-flex font-14 gap-1">
//             <div className={`bg-white border-grey pt-2 pb-2 pl-4 pr-4`}>
//                 1
//             </div>
//             <div className="bg-white pt-2 pb-2 pl-4 pr-4 rounded-50">
//                 2
//             </div>
//             <div className="bg-white pt-2 pb-2 pl-4 pr-4 rounded-50">
//                 3
//             </div>
//             <div className="bg-white pt-2 pb-2 pl-4 pr-4 rounded-50">
//                 4
//             </div>
//         </div>
//         <div className="d-flex d-align-center">
//             <img src="/assets/arrow-right.svg" style={{height:"24px", width:"24px"}}/>
//         </div>
//     </div>
// </div>
//   )
// }

// export default Pagination
